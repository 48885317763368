import React from "react"
import { GlobalAuthProvider } from "./src/context/auth/auth"
import { initialState, authReducer } from "./src/context/auth/authReducer"
import { loadConfig } from "./src/utils/config/config"

const overrideConfig =
  process.env.GATSBY_CONFIG_OVERRIDE &&
  JSON.parse(process.env.GATSBY_CONFIG_OVERRIDE)

// Utility to store tracking parameters
const storeTrackingParams = isAuthenticated => {
  const paramsString = window.location.search
  const searchParams = new URLSearchParams(paramsString)

  let redirectNeeded = false

  searchParams.forEach((value, key) => {
    if (key.startsWith("utm_")) {
      const itmKey = isAuthenticated ? key.replace("utm_", "itm_") : key
      sessionStorage.setItem(itmKey, value.trim())

      if (isAuthenticated) {
        // Update URL to replace utm_ with itm_
        searchParams.delete(key)
        searchParams.set(itmKey, value.trim())
        redirectNeeded = true
      }
    }
  })

  if (redirectNeeded) {
    // Redirect to the updated URL with itm_ parameters
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`
    if (window.location.href !== `${window.location.origin}${newUrl}`) {
      window.location.replace(newUrl) // Full page redirect to ensure URL is updated
    }
  }
}

export function onClientEntry() {
  // Read the authentication status from localStorage
  const userInfo = localStorage.getItem("user_info")
  const isAuthenticated = JSON.parse(userInfo)?.tid || false

  storeTrackingParams(isAuthenticated)
}

export function onRouteUpdate({ location }) {
  const paramsString = window.location.search
  const searchParams = new URLSearchParams(paramsString)

  const userInfo = localStorage.getItem("user_info")
  const isAuthenticated = JSON.parse(userInfo)?.tid || false

  let hasUTMSaved = false
  let hasITMSaved = false
  const keys = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
    "utm_partner",
  ]

  keys.forEach(key => {
    const value = sessionStorage.getItem(key)
    const itmKey = isAuthenticated ? key.replace("utm_", "itm_") : key

    if (value) {
      if (isAuthenticated) {
        hasITMSaved = true
        searchParams.set(itmKey, value)
      } else {
        hasUTMSaved = true
        searchParams.set(key, value)
      }
    }
  })

  if (hasUTMSaved || hasITMSaved) {
    window.history.replaceState(
      location.state,
      "",
      `${location.pathname}?${searchParams.toString()}`
    )
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <GlobalAuthProvider initialState={initialState} reducer={authReducer}>
      {element}
    </GlobalAuthProvider>
  )
}
